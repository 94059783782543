import React from "react";
import styles from "./contentLeft.module.scss";
import { Link } from "gatsby";

const ContentLeft = (props) => {
  switch (props.page) {
    case "index":
      return (
        <div className={styles.ContentLeft}>
          <div className={styles.color_div}></div>
          <div className={styles.content_container}>
            <div className={styles.text_container}>
              <h2>{props.content.title}</h2>
              <p>{props.content.details}</p>
              <Link to={props.content.route}>Learn more</Link>
            </div>
            <img src={props.content.image} alt={props.content.alt} />
          </div>
        </div>
      );

    case "meet-linda":
      return (
        <div className={styles.ContentLeft}>
          <div className={styles.content_container}>
            <div className={styles.text_container_meetLinda}>
              <p>
                <span>{props.content.title}</span>
                {props.content.details}
              </p>
            </div>
            <img
              src={props.content.image}
              alt={props.content.alt}
              title={props.content.alt}
            />
          </div>
          <div className={styles.color_div}></div>
        </div>
      );

    case "on-the-issues":
      return (
        <div className={styles.ContentLeft}>
          <div className={styles.content_container}>
            <div className={styles.text_container_onTheIssues}>
              <h2>{props.content.title}</h2>
              <p>{props.content.details}</p>
            </div>
            <img src={props.content.image} alt={props.content.alt} />
          </div>
          <div className={styles.color_div}></div>
        </div>
      );

    default:
      break;
  }
};

export default ContentLeft;
