import React, { useState, useEffect } from "react";
import styles from "./banner.module.scss";
import onTheIssues from "../images/onTheIssues_banner.jpg";
import meetLinda from "../images/meetLinda_banner_2.jpg";
import getInvolved from "../images/getInvolved_banner.jpg";
import contactLinda from "../images/contactLinda_banner.jpg";

const Banner = (props) => {
  const [pages] = useState({
    "on-the-issues": {
      image: onTheIssues,
      header_text: "ON THE ISSUES",
    },
    "meet-linda": {
      image: meetLinda,
      header_text: "MEET LINDA",
    },
    "contact-linda": {
      image: contactLinda,
      header_text: "GET IN TOUCH",
    },
    "get-involved": {
      image: getInvolved,
      header_text: "GET INVOLVED",
    },
    "404": {
      image: contactLinda,
      header_text: "ERROR",
    },
  });

  const [currentPage, setCurrentPage] = useState({});

  useEffect(() => {
    let page = pages[props.page];
    return setCurrentPage(page);
  }, [props.page, pages]);

  return (
    <>
      <div className={styles.Banner}>
        <h1>{currentPage.header_text}</h1>
        <span>{currentPage.header_text}</span>
        <img src={currentPage.image} alt={currentPage.header_text} />
      </div>
      {props.page === "meet-linda" || props.page === "on-the-issues" ? (
        <div className={styles.color_div_lg} />
      ) : null}
    </>
  );
};

export default Banner;
