import React from "react";
import styles from "./pages.module.scss";

import Banner from "../components/banner";
import ContentLeft from "../components/contentLeft";
import ContentRight from "../components/contentRight";
import { onTheIssues } from "../utils/content";

export default () => (
  <div className={styles.Page}>
    <Banner page="on-the-issues" />
    <div className={styles.content_container}>
      {onTheIssues.map((section, index) => {
        if (index % 2 === 0) {
          return (
            <ContentRight content={section} page="on-the-issues" key={index} />
          );
        } else {
          return (
            <ContentLeft content={section} page="on-the-issues" key={index} />
          );
        }
      })}
    </div>
  </div>
);
