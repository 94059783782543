import image2 from "../images/index_image_2.jpg";
import image3 from "../images/index_image_3.jpg";
import image1_2 from "../images/index_image_1_2.jpg";

import OTIeducation from "../images/onTheIssues_Education.jpg";
import OTIeconomy from "../images/onTheIssues_Economy_2.jpg";
import OTIhealthcare from "../images/onTheIssues_HealthCare_2.jpg";
import OTIenvironment from "../images/onTheIssues_Environment.jpg";
import OTIpublicsafety from "../images/onTheIssues_PublicSafety_1.jpg";
import ML1 from "../images/meetLinda_1.jpg";
import ML2 from "../images/meetLinda_2.jpg";
import ML3 from "../images/meetLinda_3.jpg";
import ML4 from "../images/meetLinda_4.jpg";

export const index = [
  {
    title: "Meet Linda",
    details: `Linda was born and raised in Salt Lake and has deep ties to our community. After graduating from Georgetown University and the UH William S. Richardson School of Law, she returned home to give back and raise her family.`,
    image: image1_2,
    alt: "Meet Linda",
    route: "/meet-linda",
  },
  {
    title: "On the Issues",
    details:
      "Hawaii is always changing and we must work together to address new challenges. Here are Linda’s positions on some of the pressing issues facing our community.",
    image: image2,
    alt: "On the Issues",
    route: "/on-the-issues",
  },
  {
    title: "Get Involved",
    details:
      "Join our team! It takes a group effort to get our message out and we can use your help! ",
    image: image3,
    alt: "Get Involved",
    route: "/get-involved",
  },
];

export const onTheIssues = [
  {
    title: "EDUCATION IS KEY",
    details:
      "Our children deserve the best in education. All of our schools should provide our children with the best teachers to inspire our students, the best educational resources including technology, and the best learning environment to make them feel safe. Investing in our children is an investment in the future and we are all committed to making sure that our students and schools are successful. But such investment and commitment cannot happen without proper resources, and current funding levels are threatening to undermine the quality of education for our keiki.",
    image: OTIeducation,
    alt: "Education is Key",
  },
  {
    title: "ECONOMIC PROSPERITY",
    details:
      "Our state is facing a tough economic situation, with many people worrying about how they can afford to keep their homes and care for their families. We need new ideas and fresh perspectives to find ways to balance our state budget. I believe that recovery and prosperity are within our reach, as long as we remain focused on long term economic growth and investing in our future.",
    image: OTIeconomy,
    alt: "Economic Prosperity",
  },
  {
    title: "QUALITY HEALTH CARE",
    details:
      "Quality health care should be affordable and accessible to all. Our community is home to some of the finest hospitals and clinics in the State and people come to Moanalua and Salt Lake to receive quality care from trusted professionals. We too should be able to benefit from the top-notch health care available right down the street.",
    image: OTIhealthcare,
    alt: "Quality Health Care",
  },
  {
    title: "PROTECTING OUR ENVIRONMENT",
    details:
      "We are truly fortunate to live in Hawaii. We are surrounded by natural beauty and bountiful resources. Our environment provides ample and under-utilized opportunities for sustainable agriculture and renewable energy sources. Future generations deserve to enjoy the same Hawaii that we do without the burden of oil costs to ship food from outside the State and to power their daily lives.",
    image: OTIenvironment,
    alt: "Protecting our Environment",
  },
  {
    title: "SAFE COMMUNITIES",
    details:
      "We deserve a safe community to call home. Property crime and vandalism are on the rise, and heavy traffic through our neighborhoods poses a serious risk to pedestrians including seniors and school children. We must work as a community to make our streets safer, for the benefit of our families and neighbors.",
    image: OTIpublicsafety,
    alt: "Safe Communities",
  },
];

export const meetLinda = [
  {
    title: "LINDA IS HONORED TO SERVE",
    details:
      " as the State Representative for Moanalua, Salt Lake and Aliamanu, since 2010.  She is Vice-Chair of the House Consumer Protection and Commerce Committee, addressing issues about health care, insurance, business regulation and professional licensing.  Linda was an associate attorney at Porter McGuire Kiakona & Chow, LLP for several years, specializing in real estate, condominium associations, community associations and business litigation. She is married to Pono Chong and they have two children.",
    image: ML1,
    alt: "Linda Ichiyama, State Representative House District 32",
  },
  {
    title: "PUBLIC SERVICE",
    details:
      " has always been important to Linda.  While a senior at Moanalua High School, Linda served as the student member on the Hawaii State Board of Education.  Representing public high school students in Hawaii, she advised the Board on issues facing the education system with insight and perspective.  She was also active in the Hawaii State Student Council and the Moanalua Interact Club, which fostered her appreciation for volunteerism. These experiences taught her that a quality public education is not just an option for Hawaii's keiki, but a necessity.",
    image: ML2,
    alt:
      "Linda being sworn in as the BOE Student Member in 2002 by Chief Justice Ronald T. Moon.",
  },
  {
    title: "THE VALUE OF EDUCATION",
    details:
      " was reinforced upon Linda when she enrolled at Georgetown University alongside students from around the world.  There, she earned a degree in International Politics.  While in Washington, D.C., Linda worked on Capitol Hill where she gained policy-making experience on a national level.",
    image: ML3,
    alt:
      "Linda celebrating her graduation from Georgetown University in 2007 with her mother, Carole Ichiyama.",
  },
  {
    title: "LINDA IS COMMITTED",
    details:
      " to many service organizations in our community and she knows first-hand how the law can empower each of us.  While graduating with honors from William S. Richardson School of Law at the University of Hawaii, Linda also participated in a number of student associations.  Her dedication to the community continues through her service to several organizations, including the Moanalua Lions Club and as a board member of the Honolulu Community Action Program (HCAP).",
    image: ML4,
    alt:
      "Linda helping at a Moanalua Lions Club service project for Moanalua Elementary School.",
  },
];
